import React from "react";
import { Link } from "gatsby";

const PreviousLesson = ({ href }) => (
	<Link className="course-pagination btn btn-light" to={href}>
		Go Back
	</Link>
);

export default PreviousLesson;
