import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import SEO from "../components/seo";
import Layout from "../components/layout";
import LeftNav from "../components/navs/left-nav";
import TopNav from "../components/navs/top-nav";
import Footer from "../components/footer";
import NextLesson from "../components/pagination-buttons/next-lesson";
import PreviousLesson from "../components/pagination-buttons/previous-lesson";

import "prismjs/themes/prism.css";
import "../styles/main.scss";

export default function CourseTemplate({ data }) {
	const { mdx: course } = data;

	return (
		<Layout>
			<SEO
				title={course.frontmatter.title}
				description={course.frontmatter.description}
				canonical={course.frontmatter.canonical}
			/>

			<TopNav />

			<Container>
			<Row
				className={`main-content-wrapper flex-grow-1 ${
					course.frontmatter.hideLeftNav === true ? "left-nav-hidden" : ""
				}`}
			>
				<Col md={3} xl={2} className="course-nav-left">
					<LeftNav currentPath={course.fields.slug} />
				</Col>
				<Col as="main" className="course-main">
					<Row>
						<Col>
							<article>
								<header>
									<h1>{course.frontmatter.title}</h1>
								</header>
								<MDXRenderer>{course.body}</MDXRenderer>
							</article>
						</Col>
					</Row>
					<Row className="justify-content-between">
						<Col xs="auto">
							{course.frontmatter.previousLesson && (
								<PreviousLesson href={course.frontmatter.previousLesson} />
							)}
						</Col>
						<Col xs="auto">
							{course.frontmatter.nextLesson && <NextLesson href={course.frontmatter.nextLesson} />}
						</Col>
					</Row>
				</Col>
			</Row>
			</Container>

			<Footer />
		</Layout>
	);
}

export const pageQuery = graphql`
	query CourseByPath($id: String!) {
		mdx(id: { eq: $id }) {
			id
			body
			frontmatter {
				title
				nextLesson
				previousLesson
				canonical
				hideLeftNav
			}
			fields {
				slug
			}
		}
		site {
			siteMetadata {
				title
			}
		}
	}
`;
