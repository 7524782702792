import React from "react";
import { Link } from "gatsby";

const NextLesson = ({ href }) => (
	<Link className="course-pagination btn btn-secondary" to={href}>
		Continue
	</Link>
);

export default NextLesson;
