import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function LeftNav({ currentPath }) {
	const leftNavJson = useStaticQuery(
		graphql`
			query LeftNavContent {
				allLeftNavOwnerJson {
					edges {
						node {
							path
							title
							subItems {
								path
								title
							}
						}
					}
				}
				allLeftNavCollaboratorJson {
					edges {
						node {
							path
							title
							subItems {
								path
								title
							}
						}
					}
				}
				allLeftNavFirehoseJson {
					edges {
						node {
							path
							title
							subItems {
								path
								title
							}
						}
					}
				}
			}
		`
	);

	const navItems = [];
	var navItemsRaw = [];

	if (currentPath.startsWith("/owner")) {
		navItemsRaw = leftNavJson.allLeftNavOwnerJson;
	} else if (currentPath.startsWith("/collaborator")) {
		navItemsRaw = leftNavJson.allLeftNavCollaboratorJson;
	} else {
		navItemsRaw = leftNavJson.allLeftNavFirehoseJson;
	}

	navItemsRaw.edges.forEach((navItem) => {
		let navItemId = navItem.node.path.replace(/\//g, "-").replace(/^-|-$/g, "");

		navItems.push(
			<Nav.Item id={navItemId} key={navItemId}>
				<Link to={navItem.node.path} className="nav-link" activeClassName="active">
					{navItem.node.title}
				</Link>

				{navItem.node.subItems != null &&
					currentPath.includes(navItem.node.path) &&
					navItem.node.subItems.map((subItem) => {
						let childNavItemId = subItem.path.replace(/\//g, "-").replace(/^-|-$/g, "");

						return (
							<Nav.Item id={childNavItemId} key={childNavItemId}>
								<Link
									to={subItem.path}
									className="nav-left-secondary nav-link"
									activeClassName="active"
								>
									{subItem.title}
								</Link>
							</Nav.Item>
						);
					})}
			</Nav.Item>
		);
	});

	return (
		<Navbar expand="md" className="navbar-dark">
			<Navbar.Toggle aria-controls="courseNavLeft" />
			<Navbar.Collapse id="courseNavLeft">
				<Nav activeKey={currentPath} className="flex-column mr-auto">
					{navItems}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

LeftNav.propTypes = {
	currentPath: PropTypes.string.isRequired,
};

export default LeftNav;
